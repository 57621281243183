<template>
  <div class="nav-bar">
    <div class="nav-bar__back"><img src="@/assets/svg/arrows/back.svg" alt="" @click="$router.go(-1)"/>{{ $t('back') }}</div>
    <h1 class="nav-bar__title">{{ $t('sustainability-project-directories') }}</h1>
    <div class="nav-bar__links">
      <router-link
          v-for="(link, index) in links"
          :to="link.path"
          :key="index"
          class="nav-bar__links-link"
      >
        {{ link.text }}
      </router-link>
    </div>
    <ui-menu v-model="visibleSettings">
      <template #activator>
        <img alt="" :src="require(`@/assets/svg/icon-settings-${settingsColor}.svg`)" class="nav-bar__settings" />
      </template>
      <template #body>
        <div class="sort">
          <router-link
              v-for="(link, index) in links"
              :to="link.path"
              :key="index"
              class="nav-bar__links-link"
              @click.native="visibleSettings = !visibleSettings"
          >
            {{ link.text }}
          </router-link>
        </div>
      </template>
    </ui-menu>
  </div>
</template>

<script>
import {getUserRole} from "@/utils/user";
import {mapGetters} from "vuex";

export default {
  name: "EsgGuidesNavBar",
  methods: {getUserRole},
  components: {
    UiMenu: () => import('@/components/ui/UiMenu.vue'),
  },
  data() {
    return {
      links: [
        { id: 0, text: this.$t('project-categories'), path: '/esg-guides/project-categories' },
        { id: 1, text: this.$t('project-types'), path: '/esg-guides/project-types' },
        { id: 2, text: this.$t('project-subtypes'), path: '/esg-guides/project-subtypes' },
      ],
      visibleSettings: false,
    }
  },
  computed: {
    ...mapGetters(['getUser']),
    settingsColor () {
      return this.visibleSettings ? 'red' : 'black'
    }
  }
}
</script>

<style lang="scss" scoped>
.nav-bar {
  position: relative;
  max-width: 320px;

  @media (max-width: 1200px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
    width: 100%;
    z-index: 11;
  }

  &__back {
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
  }

  &__title {
    margin-bottom: 40px;
    margin-top: 20px;
    font-weight: 400;
    font-size: 20px;
    line-height: 22px;
    color: #343432;
    cursor: pointer;

    @media (max-width: 1200px) {
      margin-bottom: 0;
    }
  }

  &__links {
    display: flex;
    flex-direction: column;
    gap: 30px;

    @media (max-width: 1200px) {
      display: none;
    }

    .router-link-active {
      color: red;
    }

    &-link {
      font-weight: 400;
      font-size: 16px;
      line-height: 15px;
      color: #343432;
      cursor: pointer;
      white-space: nowrap;
    }
  }

  &__settings {
    display: none;

    @media (max-width: 1200px) {
      display: inline-block;
    }
  }

  .sort {

    .router-link-active {
      color: red;
    }
  }
}
</style>

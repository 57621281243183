<template>
  <div class="esg-guides">
    <esg-guides-nav-bar />
    <router-view></router-view>
  </div>
</template>

<script>
import EsgGuidesNavBar from "@/views/esg/esgGuides/EsgGuidesNavBar.vue";

export default {
  name: "EsgGuides",
  components: {EsgGuidesNavBar},
}
</script>

<style lang="scss" scoped>
.esg-guides {
  display: flex;
  justify-content: space-between;
  padding: 150px 50px 56px 50px;
  min-height: 100vh;
  height: 100%;
  background: #F5F5F5;

  @media (max-width: 1200px) {
    flex-direction: column;
    justify-content: normal;
    gap: 20px;
    padding: 99px 20px 50px 20px;
  }
}
</style>